import React from "react";
import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { useGetDriversQuery } from "state/api";
import { Header } from "components";

// Drivers
const Drivers = () => {
  // theme
  const theme = useTheme();
  // get data
  const { data, isLoading } = useGetDriversQuery();

  // data columns
  const columns = [
    {
      field: "_id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "username",
      headerName: "Username",
      flex: 0.5,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "phoneNo",
      headerName: "Phone No.",
      flex: 0.5,
    },
    {
      field: "role",
      headerName: "Role",
      flex: 0.5,
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      {/* Header */}
      {/* <Header title="Drivers" subtitle="List of Drivers" /> */}
      <Header title="Drivers" subtitle="List of Drivers" />

      {/* Content */}
      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.common.black,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiTablePagination-selectLabel": {
            fontSize: "12px",
          },
          "& .MuiSelect-select": {
            fontSize: "12px",
            textAlign: "center",
            marginTop: "10px",
          },
          "& .MuiTablePagination-displayedRows": {
            fontSize: "12px"
          },
          "& .MuiDataGrid-toolbarContainer .MuiButtom-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        {/* Grid table */}
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row._id}
          rows={data || []}
          columns={columns}
          style={{ fontSize: '15px' }}

        />
      </Box>
    </Box>
  );
};

export default Drivers;
