// import React from "react";
// import "./login.css";
// // import { handleLogin } from '../../App';
// import { useNavigate } from "react-router-dom";
// import { userPostlogin } from "state/api";
// const Login = ({ onLogin }) => {
//   const navigate = useNavigate();
//   const handleSubmit = (event) => {
//     event.preventDefault(); // Prevent default form submission behavior

//     // Here you can handle form submission logic, such as sending the data to a server
//     // For now, let's just log the form data
//     const formData = new FormData(event.target);
//     const email = formData.get("email");
//     const password = formData.get("password");
//     onLogin(true);
//     navigate('/users', { replace: true })
    
//   };
//   return (
//     <>
//       <section>
//         <div className="auth">
//           <h1>Login</h1>
//           <form onSubmit={handleSubmit}>
//             <input
//               type="email"
//               name="email"
//               id="email"
//               autoComplete="off"
//               placeholder="Email"
//               required={true}
//             />
//             <input
//               type="password"
//               name="password"
//               id="password"
//               placeholder="Password"
//               required={true}
//             />
//             <p>Forgot Password?</p>
//             <button type="submit">Login</button>
//           </form>
//         </div>
//       </section>
//     </>
//   );
// };

// export default Login;


import React, { useState } from "react";
import "./login.css";
import { useNavigate } from "react-router-dom";
import { usePostloginQuery } from "state/api";

const Login = ({ onLogin }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const [password, setPassword] = useState("");
  const callLoginApi =  usePostloginQuery({
    email: email,
    password: password,
    role: "admin",
  });
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    onLogin(true);
    // try {

    //  const response= callLoginApi;
   
    //   console.log(response);
    //   if (response.ok) {
    //     // Redirect to '/users' after successful login
    //     navigate('/users', { replace: true });
    //   } else {
    //     // Handle login error
    //     console.error("Login failed:", response.endpointName);
    //   }
    //   // // Redirect to '/users' after successful login
    //   // navigate('/users', { replace: true });
    // } catch (error) {
    //   console.error("Login failed catch:", error);
    //   // Handle login error
    // }
    setError("");
    if(email == "admin@gmail.com" &&  password == "12345678"){
      localStorage.setItem('user_data', JSON.stringify({name: 'admin'}));
      // localStorage.clear();
      navigate('/users', { replace: true });
      window.location.reload(true);   
     }else
     {

        setError("invalid credentials");
      }
  };

  return (
    <>
      <section>
        <div className="auth">
          <h1>Login</h1>
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              name="email"
              id="email"
              autoComplete="off"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required={true}
            />
            <input
              type="password"
              name="password"
              id="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required={true}
            />
<div style={{ color: 'red', fontWeight: 'bold', fontSize: '16px'  }}>{error}</div>

            <p>Forgot Password?</p>
            <button type="submit">Login</button>
          </form>
        </div>
      </section>
    </>
  );
};

export default Login;
