import React, { useState, useEffect } from "react";
// import { Box, useTheme, Button } from "@mui/material";
import {
  Box,
  useTheme,
  Button,
  Modal,
  TextField,
  DialogActions,
  DialogContent,
  DialogContentText,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  CircularProgress,
} from "@mui/material";

// import { styled } from '@mui/material/styles';

import { DataGrid } from "@mui/x-data-grid";

import { useGetYogasQuery, useCreateYogaMutation } from "state/api";
import { Header } from "components";

// import { useMutation, useQueryClient } from 'react-query';

// Firebase imports

import firebase from '../../../services/firebase';
// import firebase from 'firebase';
import { getAuth, signInAnonymously } from "firebase/auth";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

// import {createYoga} from '../../../../../server/controllers/client';
// import {YogaModel} from '../../../models/Yoga';


// Custom audio player component
const AudioPlayer = ({ url }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = React.createRef();

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
      audioRef.current.currentTime = 0; // Reset playback time
    }
  }, [isPlaying, url]);

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
  };


  // // Create MUI styles for margin
  // const useStyles = makeStyles({
  //   audioElement: {
  //     margin: "10px 15px",
  //   },
  // });

  // const classes = useStyles();

  //     // Create a styled component for the audio element
  // const StyledAudio = styled('audio')({
  //   margin: '10px 15px', // Apply the desired margin
  // });

  return (
    <div>
      {/* // // Use the styled component in your JSX
    // <StyledAudio ref={audioRef} src={url} controls /> */}
      <audio ref={audioRef} src={url} controls
        style={{
          // margin: "10px 15px", 
          padding: "8px 15px 2px 0px"
        }}
      />

      {/* <button onClick={handlePlayPause}>
        {isPlaying ? "Pause" : "Play"}
      </button> */}
    </div>
  );
};



// Yoga
const Yoga = () => {


  // get data
  const { data, isLoading } = useGetYogasQuery();

  // let yogaData;

  // const { mutate: createYogaMutation } = useCreateYogaQuery({yogaData}); // Destructure mutate function


  // const result = await useCreateYogaQuery();
  // const { mutate: createYogaMutation } = result;

  // const createYogaMutation = useCreateYogaQuery()?.mutate;

  // const createYogaMutation = useCreateYogaQuery();

  // const createYoga = useMutation((data) => createYogaMutation.mutate(data));

  // const { data: createYogaData, error: createYogaError } = useCreateYogaQuery();

  // const queryClient = useQueryClient();


  // const createYogaMutation = useCreateYogaMutation();

  // const createYogaMutation = async () => {
  //   useCreateYogaMutation();
  // }

  // // Wrap the mutation in useMutation hook
  // const { mutate: createYoga } = useMutation(
  //   (yogaData) => createYogaMutation.mutate(yogaData),
  //   {
  //     // Add any additional configuration options if needed
  //     onSuccess: () => {
  //       // Refetch the Yogas query after a successful mutation
  //       queryClient.invalidateQueries('Yogas');
  //     },
  //     onError: (error) => {
  //       console.error("Error creating yoga:", error);
  //     },
  //   }
  // );

  // const { mutate: createYoga } = useMutation(
  //   (yogaData) => {
  //     // Implement your API call or data manipulation logic here
  //     // Replace with your actual API call
  //     useCreateYogaMutation(yogaData);
  //     return new Promise((resolve) => {
  //       setTimeout(() => {
  //         resolve({ data: "Yoga created successfully!" }); // Simulate API response
  //       }, 1000); // Simulate delay for demo
  //     });
  //   },
  //   {
  //     // Optional mutation options (e.g., onSuccess, onError)
  //   }
  // );


  const [createYoga] = useCreateYogaMutation();
  // const {mutate: createYoga} = useCreateYogaMutation();

  // const handleCreateYoga = async (newYogaData) => {
  //   try {
  //     const response = await createYoga(newYogaData);
  //     console.log("Yoga created successfully:", response.data);
  //   } catch (error) {
  //     console.error("Error creating yoga:", error);
  //   }
  // };

  // theme
  const theme = useTheme();

  // data columns
  const columns = [
    // {
    //   field: "_id",
    //   headerName: "ID",
    //   flex: 1,
    // },
    {
      field: "exerciseName",
      headerName: "Exercise Name",
      flex: 1,
    },
    {
      field: "exerciseCategory",
      headerName: "Exercise Category",
      flex: 1,
    },
    {
      field: "exerciseUrl",
      // headerName: "Exercise Url",
      headerName: "Content",
      flex: 1,
      renderCell: (params) => {
        return <AudioPlayer url={params.value} />;
      },
    },
    {
      field: "exerciseDescription",
      headerName: "Exercise Description",
      flex: 1,
    },
  ];

  const [open, setOpen] = useState(false);
  const [exerciseName, setExerciseName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [exerciseDescription, setExerciseDescription] = useState("");
  const [selectedFileType, setSelectedFileType] = useState("image/*"); // Initial file type (image)

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleFileTypeChange = (event) => {
    setSelectedFileType(event.target.value);
  };

  // const handleFileChange = (event) => {
  //   setSelectedFile(event.target.files[0]);
  // };

  // const handleSubmit = async () => {
  //   // Implement validation logic here
  //   if (!exerciseName || !selectedFile) {
  //     // Handle missing data errors
  //     return;
  //   }

  //   // Implement upload logic (e.g., using a library like Axios)
  //   // ...

  //   // Update yoga data in your application state (based on your data structure)
  //   // ...
  // };

  const [user, setUser] = useState(null);
  const [exerciseNameError, setExerciseNameError] = useState(null);
  const [exerciseCategory, setExerciseCategory] = useState("yoga"); // Initial selection
  const [exerciseCategoryError, setExerciseCategoryError] = useState(null);
  const [selectedFileError, setSelectedFileError] = useState(null);
  const [exerciseDescriptionError, setExerciseDescriptionError] = useState(null);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false); // State for loading indicator

  const handleExerciseNameChange = (event) => {
    setExerciseName(event.target.value);
    setExerciseNameError(event.target.value ? null : "Enter exercise name");
  };

  const handleCategoryChange = (event) => {
    setExerciseCategory(event.target.value);
    setExerciseCategoryError(event.target.value ? null : "Please select a category");
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setSelectedFileError(event.target.files.length > 0 ? null : "Select a file");
  };

  const handleExerciseDescriptionChange = (event) => {
    setExerciseDescription(event.target.value);
    setExerciseDescriptionError(event.target.value ? null : "Enter exercise description");
  };

  const handleAnonymousSignIn = async () => {
    try {
      // const auth = getAuth();
      const auth = getAuth(firebase);
      const userCredential = await signInAnonymously(auth);
      console.log("User signed in anonymously:", userCredential.user);
      setUser(userCredential.user);
      // Proceed with file upload (assuming user is authenticated)
    } catch (error) {
      console.error("Error signing in anonymously:", error);
      // Handle sign-in errors appropriately (e.g., display error message)
    }
  };


  const handleFileUpload = async (selectedFile) => {
    if (!selectedFile) {
      return; // Handle missing file
    }

    const storage = getStorage();
    const storageRef = ref(storage, `yoga_files/${selectedFile.name}`); // Adjust storage path as needed
    const uploadTask = uploadBytesResumable(storageRef, selectedFile);

    // uploadTask.on(
    //   "state_changed",
    //   (snapshot) => {
    //     // Observe state change events such as progress, pause, and resume
    //     switch (snapshot.state) {
    //       case "paused":
    //         console.log("Upload is paused");
    //         break;
    //       case "running":
    //         console.log("Upload is in progress");
    //         break;
    //       default:
    //         // console.log("Upload is in progress");
    //         break;
    //     }
    //   },
    //   (error) => {
    //     console.error("Error uploading file:", error);
    //     // Handle upload errors appropriately
    //   },
    //   async () => {
    //     const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
    //     console.log("File uploaded successfully:", downloadURL);
    //     const yogaData = {
    //       exerciseName: exerciseName,
    //       downloadURL: downloadURL,
    //     }
    //     // useCreateYogaQuery(data);
    //     /// Use the mutate function to trigger the mutation
    //     // createYogaMutation.mutate(data);
    //     // createYoga.mutate(data);

    //     // const createYogaData = await useCreateYogaQuery({ exerciseName, downloadURL });

    //     // await handleYogaDataUpdate(data); // Replace with your implementation

    //     // createYogaMutation({ yogaData }); // Use the createYogaMutation function from the Hook



    //     // const { mutate: createYoga } = useCreateYogaQuery(); // Destructure mutate function
    //     // createYoga(yogaData);


    //     // // Use the mutateAsync function from the hook
    //     // try {
    //     //   await createYogaMutation.mutateAsync(yogaData);
    //     // } catch (error) {
    //     //   console.error("Error creating yoga:", error);
    //     // }

    //     // try {
    //     //   // Use the mutate function from the mutation hook
    //     //   await createYogaMutation.mutate(yogaData);
    //     // } catch (error) {
    //     //   console.error("Error creating yoga:", error);
    //     // }


    //     // createYoga(yogaData);


    //     // await createYogaMutation.mutate({
    //     //   exerciseName: exerciseName,
    //     //   downloadURL: downloadURL, // replace with your actual download URL
    //     // });

    //     // // Refetch the Yogas query after a successful mutation
    //     // queryClient.invalidateQueries('Yogas');


    //     createYoga(yogaData);

    //     // handleCreateYoga(yogaData);

    //     // // Use the downloadURL to update yoga data (assuming proper integration with your state and API call)
    //     // try {
    //     //   // Create a new Yoga document with exerciseName and exerciseUrl
    //     //   const newYoga = new Yoga({ exerciseName: exerciseName, exerciseUrl: downloadURL });

    //     //   // Save the document to the database
    //     //   await newYoga.save();

    //     //   console.log("Yoga exercise saved successfully to database!");
    //     //   // Handle success appropriately (e.g., clear form, display a success message)
    //     // } catch (error) {
    //     //   console.error("Error saving yoga exercise to database:", error);
    //     //   // Handle error appropriately (e.g., display an error message)
    //     // }

    //   }
    // )

    const promises = [
      uploadTask.on('state_changed', (snapshot) => {
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is in progress');
            break;
          default:
            break;
        }
      }),
      uploadTask.on('error', (error) => {
        console.error('Error uploading file:', error);
      }),
      uploadTask.then(async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        console.log('File uploaded successfully:', downloadURL);
        const yogaData = {
          exerciseName: exerciseName,
          exerciseCategory: exerciseCategory,
          exerciseUrl: downloadURL,
          exerciseDescription: exerciseDescription,
        };
        await createYoga(yogaData);
      }),
    ];

    await Promise.all(promises);
  };

  // const handleYogaDataUpdate = async (data) => {
  //   console.log("Data update logic here:", data.exerciseName, data.downloadURL);
  //   // Replace with your actual method (e.g., using an API call)
  //   // useCreateYogaQuery(data);
  //   useCreateYogaQuery({ exerciseName, downloadURL });
  // };

  const handleSubmit = async () => {
    // ... other logic

    if (!exerciseName) {
      setExerciseNameError("Enter exercise name");
      return;
    }

    if (!exerciseCategory) {
      setExerciseCategoryError("Please select a category");
      return;
    }

    if (!selectedFile) {
      setSelectedFileError("Select a file");
      return;
    }

    if (!exerciseDescription) {
      setExerciseDescriptionError("Enter exercise description");
      return;
    }

    setIsLoadingSubmit(true); // Start loading before asynchronous operations

    // Attempt anonymous sign-in before upload
    await handleAnonymousSignIn();

    // Upload the selected file
    await handleFileUpload(selectedFile);

    // const formData = new FormData();
    // formData.append("exerciseName", exerciseName);
    // formData.append("file", selectedFile);

    // // Use the mutation hook
    // const { data, error } = await useCreateYogaMutation(formData);

    // if (data) {
    //   console.log("Yoga exercise created successfully:", data);
    //   // You can handle any post-creation logic here
    // }

    // if (error) {
    //   console.error("Error creating yoga exercise:", error);
    //   // Handle error state here
    // }

    setIsLoadingSubmit(false); // Stop loading after data is created

    setOpen(false); // Close the modal after successful submission

    // // Refresh the "yogas" query after successful submission
    // useQueryClient().invalidateQueries('yogas');
    window.location.reload();
  };

  //   // Create Yoga
  // const createYoga = async (req, res) => {
  //   // try {

  //   //   const yogas = await Yoga.find();
  //   //   res.status(200).json(yogas);
  //   // } catch (error) {
  //   //   res.status(404).json({ message: error.message });
  //   // }
  //   // Use the downloadURL to update yoga data (assuming proper integration with your state and API call)
  //   try {

  //     // const { data } = req.query;
  //     const { data } = req.body;

  //     console.log("y 333", data);

  //     // Create a new Yoga document with exerciseName and exerciseUrl
  //     const newYoga = new YogaModel({ exerciseName: data.exerciseName, exerciseUrl: data.downloadURL });

  //     // const { exerciseName, downloadURL } = req.body; // Assuming these are the fields you need from the request body

  //     // const newYoga = new YogaModel({ exerciseName, exerciseUrl: downloadURL });

  //     // Save the document to the database
  //     await newYoga.save();

  //     console.log("Yoga exercise saved successfully to database!");
  //     // Handle success appropriately (e.g., clear form, display a success message)
  //     res.status(200).json(newYoga);
  //   } catch (error) {
  //     console.error("Error saving yoga exercise to database:", error);
  //     res.status(404).json({ message: error.message });
  //   }
  // };

  return (

    <Box m="1.5rem 2.5rem">
      {/* Header */}
      {/* <Header title="Yoga" subtitle="List of Yoga" /> */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Yoga" subtitle="List of Yoga" />
        <Button variant="contained" color="primary" size="medium" onClick={handleOpen}>
          {'Add Exercise'}
        </Button>
      </Box>

      {/* Content */}
      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButtom-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        {/* Grid table */}
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row._id}
          rows={data || []}
          columns={columns}
        />
      </Box>


      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          minWidth: 500, // Adjust the minimum width for your modal
          p: 4, // Add padding inside the modal
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper', // Set white background
          borderRadius: 2,           // Add rounded borders (adjust the value)
          boxShadow: 24
        }}>

          <DialogContent>
            {/* Error messages */}
            {exerciseNameError && <Typography variant="caption" color="error">{exerciseNameError}</Typography>}
            {selectedFileError && <Typography variant="caption" color="error">{selectedFileError}</Typography>}

            <DialogContentText>
              {"Add a new exercise:"}
            </DialogContentText>
            {/* <TextField
              label="Exercise Name"
              value={exerciseName}
              onChange={(event) => setExerciseName(event.target.value)}
              fullWidth
              margin="normal"
            /> */}
            <TextField
              label="Exercise Name"
              value={exerciseName}
              onChange={handleExerciseNameChange}
              error={!!exerciseNameError} // Set error prop based on error state
              helperText={exerciseNameError} // Set helper text for error display
              fullWidth
              margin="normal"
            />
            <br />
            <br />
            <InputLabel sx={{ mb: 1 }}>Exercise Category</InputLabel>
            <Select
              label="Exercise Category"
              value={exerciseCategory}
              onChange={handleCategoryChange}
              error={!!exerciseCategoryError}
              fullWidth
            >
              <MenuItem value="yoga">Yoga</MenuItem>
              <MenuItem value="empower">Empower</MenuItem>
            </Select>
            <br />
            <br />
            {/* <InputLabel id="demo-simple-select-label">File Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedFileType}
              label="File Type"
              onChange={handleFileTypeChange}
            >
              <MenuItem value={"image/*"}>Image</MenuItem>
              <MenuItem value={"video/*"}>Video</MenuItem>
            </Select>
            <br /> */}
            <input
              type="file"
              accept="audio/mpeg"
              onChange={handleFileChange}
            />
            <br />
            <br />
              <TextField
                label="Exercise Description"
                value={exerciseDescription}
                onChange={handleExerciseDescriptionChange}
                error={!!exerciseDescriptionError} // Set error prop based on error state
                helperText={exerciseDescriptionError} // Set helper text for error display
                fullWidth
                multiline  // Enable multiline
                rows={4}    // Set the number of rows to 4
                margin="normal"
                // InputProps={{
                //   rows: 4,
                // }}
                // minRows={4} // Set minimum rows
                // maxRows={4}
              />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            {/* <Button onClick={handleSubmit} color="primary">
              Save
            </Button> */}

            <Button onClick={handleSubmit} color="primary" disabled={isLoadingSubmit}>
              {isLoadingSubmit ? <CircularProgress size="medium" style={{ width: 20, height: 20, color: 'white' }} /> : 'Save'}
              {/* Save */}
            </Button>

          </DialogActions>
        </Box>
      </Modal>
    </Box>
  );
};

export default Yoga;
