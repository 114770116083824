// import React, { useMemo, useState } from "react";
// import { CssBaseline, ThemeProvider } from "@mui/material";
// import { createTheme } from "@mui/material/styles";
// import { useSelector } from "react-redux";
// import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// // Theme
// import { themeSettings } from "theme";

// // Scenes
// import {
//   Layout,
//   Dashboard,
//   Products,
//   Customers,
//   Drivers,
//   Yogas,
//   Transactions,
//   Geography,
//   Overview,
//   Daily,
//   Monthly,
//   Breakdown,
//   Admin,
//   Performance,
//   Login,
// } from "scenes";


// // App
// const App = () => {
//   // Dark/Light mode
//   const mode = useSelector((state) => state.global.mode);
//   // theme setting
//   const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const handleLogin = (isSuccessful) => {
//     if(isSuccessful){
//       setIsLoggedIn(true); // For demonstration, setting isLoggedIn to true
//     }else{
//       setIsLoggedIn(false); // For demonstration, setting isLoggedIn to true
//     }

//   };

//   return (
//     <div className="app">
//       <BrowserRouter>
//         {/* Theme Provider */}
//         <ThemeProvider theme={theme}>
//           <CssBaseline />
//           <Routes>
//             {/* Routes */}
//             {!isLoggedIn && <Route path="/" element={<Login onLogin={handleLogin} />} />}
//             {isLoggedIn && (
//               <>
//                 <Route path="/dashboard" element={<Dashboard />} />
//                 <Route path="/products" element={<Products />} />
//                 <Route path="/users" element={<Customers />} />
//                 <Route path="/drivers" element={<Drivers />} />
//                 <Route path="/emoEase" element={<Yogas />} />
//                 <Route path="/transactions" element={<Transactions />} />
//                 <Route path="/geography" element={<Geography />} />
//                 <Route path="/overview" element={<Overview />} />
//                 <Route path="/daily" element={<Daily />} />
//                 <Route path="/monthly" element={<Monthly />} />
//                 <Route path="/breakdown" element={<Breakdown />} />
//                 <Route path="/admin" element={<Admin />} />
//                 <Route path="/performance" element={<Performance />} />
//               </>
//             )}
//             {isLoggedIn && <Route path="/" element={<Navigate to="/dashboard" replace />} />}
//           </Routes>
//         </ThemeProvider>
//       </BrowserRouter>
//     </div>
//   );
// };

// export default App;





import React, { useMemo, useState } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// Theme
import { themeSettings } from "theme";

// Scenes
import {
  Layout,
  Dashboard,
  Products,
  Customers,
  Drivers,
  Yogas,
  Transactions,
  Geography,
  Overview,
  Daily,
  Monthly,
  Breakdown,
  Admin,
  Performance,
  Login,
  Orders,
} from "scenes";

// App
const App = () => {
  const userData = JSON.parse(localStorage.getItem('user_data'));

  // Dark/Light mode
  const mode = useSelector((state) => state.global.mode);
  // theme setting
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const handleLogin = (isSuccessful) => {
    if (isSuccessful) {
      setIsLoggedIn(true); // For demonstration, setting isLoggedIn to true
    } else {
      setIsLoggedIn(false); // For demonstration, setting isLoggedIn to true
    }

  };
  return (
    <div className="app">
      <BrowserRouter>
        {/* Theme Provider */}
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>

            {
              userData == null ?
                <Route>
                  <Route path="/login" element={<Login onLogin={handleLogin} />} />
                  <Route path="/*" element={<Navigate to="/login" replace />} />
                </Route>
                :
                <Route element={<Layout />}>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/products" element={<Products />} />
                  <Route path="/users" element={<Customers />} />
                  <Route path="/orders" element={<Orders />} />

                  <Route path="/drivers" element={<Drivers />} />
                  <Route path="/emoEase" element={<Yogas />} />
                  <Route path="/transactions" element={<Transactions />} />
                  <Route path="/geography" element={<Geography />} />
                  <Route path="/overview" element={<Overview />} />
                  <Route path="/daily" element={<Daily />} />
                  <Route path="/monthly" element={<Monthly />} />
                  <Route path="/breakdown" element={<Breakdown />} />
                  {/* <Route path="/admin" element={<Admin />} /> */}
                  <Route path="/performance" element={<Performance />} />
                  <Route path="/*" element={<Navigate to="/users" replace />} />
                </Route>
            }
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
};

export default App;
