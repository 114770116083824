import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useMutation } from "react-query";

// Backend Api
export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl:
      process.env.REACT_APP_BASE_URL ||
      "http://localhost:5001",
  }), // base url
  reducerPath: "adminApi",
  // tags
  tagTypes: [
    "Login",
    "User",
    "Producs",
    "Customers",
    'Drivers',
    "Yogas",
    "CreateYoga",
    "Transactions",
    "Geography",
    "Sales",
    "Admins",
    "Performance",
    "Dashboard",
    "Orders"
  ],
  // endpoints
  endpoints: (build) => ({
    postlogin: build.query({
      query: ({ email, password, role }) => ({
        url:  `general/login`,
        method: "POST",
        params: { email, password, role },
      }),
      query: () => `general/login`,
      providesTags: ["Login"],
    }),
    getUser: build.query({
      query: (id) => `general/user/${id}`,
      providesTags: ["User"],
    }),
    getProducts: build.query({
      query: () => "client/products",
      providesTags: ["Products"],
    }),
    getCustomers: build.query({
      query: () => "client/customers",
      providesTags: ["Customers"],
    }),
    getDrivers: build.query({
      query: () => "client/drivers",
      providesTags: ["Drivers"],
    }),
    getYogas: build.query({
      query: () => "client/yogas",
      providesTags: ["Yogas"],
    }),
    getOrders: build.query({
      query: () => "client/getAllOrders",
      method: "GET",
      providesTags: ["Orders"],
    }),
    // createYoga: build.query({
    //   // query: () => "client/createYoga",
    //   query: ({ data }) => ({
    //     url: "client/createYoga",
    //     method: "POST",
    //     params: { data },
    //   }),
    //   providesTags: ["CreateYoga"],
    // }),
    createYoga: build.mutation({
      query: (data) => ({
        url: "client/createYoga",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["CYogas"], // Assuming "Yogas" is the tag for the data you want to refetch after creating a new yoga entry
    }),
    getTransactions: build.query({
      query: ({ page, pageSize, sort, search }) => ({
        url: "client/transactions",
        method: "GET",
        params: { page, pageSize, sort, search },
      }),
      providesTags: ["Transactions"],
    }),
    getGeography: build.query({
      query: () => "client/geography",
      providesTags: ["Geography"],
    }),
    getSales: build.query({
      query: () => "sales/sales",
      providesTags: ["Sales"],
    }),
    getAdmins: build.query({
      query: () => "management/admins",
      providesTags: ["Admins"],
    }),
    getUserPerformance: build.query({
      query: (id) => `management/performance/${id}`,
      providesTags: ["Performance"],
    }),
    getDashboard: build.query({
      query: () => "general/dashboard",
      providesTags: ["Dashboard"],
    }),
  }),
});

// export api endpoints
export const {
  usePostloginQuery,
  useGetUserQuery,
  useGetProductsQuery,
  useGetCustomersQuery,
  useGetDriversQuery,
  useGetYogasQuery,
  // useCreateYogaQuery,
  useCreateYogaMutation = () => {
    // return useMutation(api.endpoints.createYoga)
    return useMutation()
  },
  useGetTransactionsQuery,
  useGetGeographyQuery,
  useGetSalesQuery,
  useGetAdminsQuery,
  useGetUserPerformanceQuery,
  useGetDashboardQuery,
  useGetOrdersQuery,

} = api;
